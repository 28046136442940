import React, { createRef, useEffect } from 'react';

export default function Home(): React.ReactElement {
    const videoRef = createRef<HTMLVideoElement>();
    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (videoRef.current == null) {
            return;
        }

        if (vw < 600) {
            videoRef.current.src =
                'https://res.cloudinary.com/kenshikaifi/video/upload/c_scale,vc_h264,w_606/v1617546349/video_jlf74o.mp4';
        } else {
            videoRef.current.src =
                'https://res.cloudinary.com/kenshikaifi/video/upload/vc_h264/v1617546349/video_jlf74o.mp4';
        }
    }, [videoRef]);

    const onVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.autoplay = false;
        }
    };
    return (
        <>
            <section className="container p-4">
                <h3 className="title is-size-4">Okinawalaista goju-ryu karatea Helsingissä</h3>
                <p className="mt-3">
                    Kenshikai Karate-Do on Helsingissä toimiva, vuonna 2020 perustettu karateseura,
                    jonka tarkoituksena on kehittää jäseniään kokonaisvaltaisina kamppailijoina ja
                    ihmisinä.
                </p>
                <p className="mt-3">
                    Toimivat tekniikat ja hyvät fyysiset ominaisuudet ovat vain osa tehokasta
                    itsepuolustusta. Niitä tärkeämpi ase on oikea asenne. Itsevarma ja rauhallinen
                    karateka selviää useimmista hankalista tilanteista ilman, että hänen tarvitsee
                    turvautua väkivaltaan.
                </p>
                <p className="mt-3">
                    Harjoituksissamme ei kuntoilla, vaan harjoitellaan monipuolisia kamppailutaitoja
                    perinteisen okinawalaisen karaten viitekehyksessä. Meillä treenataan tosissaan,
                    mutta ei totisina.
                </p>
                <p className="mt-3">
                    Tutustu sivuillamme seuramme taustoihin ja toimintaan. Jos kiinnostuit ja haluat
                    tulla kokeilemaan, käytä yhteydenottosivulta löytyvää lomaketta.
                </p>
            </section>
            <section className="container p-4">
                <figure>
                    <video
                        ref={videoRef}
                        muted
                        poster="https://res.cloudinary.com/kenshikaifi/video/upload/so_52.5/v1617546349/video_jlf74o.jpg"
                        preload="false"
                        controls
                        onEnded={onVideoEnded}
                    />
                </figure>
                <aside className="is-italic">Tunnelmia Kenshikai Karate-Do:n harjoituksista</aside>
            </section>
        </>
    );
}
